import defaults, { baseState, buildEndpointUrl } from './utils'
import Api from '@/resources/Api'

const snippetCategories = {
    namespaced: true,
    state: baseState,
    getters: {
        ...defaults.getters,
        endpointUrl: (state) => (params) => buildEndpointUrl('snippets/categories/', 'id', params),
        tree: (state, getters) => {
            const categories = getters.all
            if (!categories?.length) return []

            const mapping = categories.reduce((acc, x) => {
                const parentId = x.parent_id || 0
                if (!acc[parentId]) acc[parentId] = []
                acc[parentId].push(x)
                return acc
            }, {})

            const buildTree = (key) => {
                return (mapping[key] || []).map(entry => ({
                    ...entry,
                    childs: buildTree(entry.id).sort((a, b) => a.sort - b.sort)
                })).sort((a, b) => a.sort - b.sort)
            }

            return buildTree(0)
        }
    },
    mutations: {
        ...defaults.mutations,
        RESET_STATE (state) {
            Object.assign(state, baseState())
        },
        REMOVE_PENDING: (state) => {
            if ('pending' in state.items) {
                delete state.items.pending
            }
        },
        UPDATE_PARENT_ID: (state, { id, parentId }) => {
            state.items[id].parent_id = parentId
        }
    },
    actions: {
        ...defaults.actions,
        addChild ({ state, getters }, { parentId = null }) {
            /**
             * This function will add a fake child until a name was properly set
             */

            const total = getters.all.filter(x => x.parent_id === parentId).length
            // Then we add the new child
            state.items.pending = {
                id: 'pending',
                name: null,
                sort: total + 1,
                parent_id: parentId,
                $focus: true // Triggers the focus for new childs
            }
        },
        removePending ({ commit }) {
            commit('REMOVE_PENDING')
        },
        async create ({ state, commit, getters, dispatch }, form) {
            const result = await Api.post(getters.endpointUrl(), form)
            await dispatch('_insert', {
                target_id: result.id,
                document: result
            })
            commit('REMOVE_PENDING')
            return state.items[result.id]
        },
        async remove ({ state, getters, commit }, { $id = null, id = null }) {
            if ($id) {
                id = state.mapId[$id]
            }

            const instance = state.items[id]
            const endpointUrl = getters.endpointUrl({ instance })

            // In the case of removed state from the client, we only mark as removed
            // But the rest will be done by the request coming from the server
            commit('SET_REMOVED_STATE', id)
            if (endpointUrl) {
                try {
                    await Api.delete(endpointUrl)
                } catch (e) {
                    commit('CANCEL_REMOVED_STATE', id)
                    throw e
                }
            }

            const _recursiveRemove = (categories, parentId) => {
                const childs = categories.filter(x => x.parent_id === parentId)
                if (childs.length === 0) return
                childs.forEach(category => {
                    _recursiveRemove(categories, category.id)
                    commit('REMOVE_ENTRY', category.id)
                })
            }
            _recursiveRemove(getters.all, id)
            commit('REMOVE_ENTRY', id)
        },
        updateTree ({ state, dispatch, commit }, { action, parentId, id, newIndex = null, oldIndex = null }) {
            // We don't handle remove because remove is always followed by an add (it's an update)
            if (action === 'remove') return

            // The event we must send is
            // the category id, parent id, position
            dispatch('updatePosition', { id, parentId, position: newIndex })

            if (action === 'add') {
                commit('UPDATE_PARENT_ID', { id, parentId })
                oldIndex = 1000 // since it's a new one, we consider all the next items to be modified
            }

            // What remains now, is either action "move" or "add" that needs to be placed properly

            // First, we get a list of currently ordered item
            const items = Object.values(state.items).filter(category => category.parent_id === parentId).sort((a, b) => a.sort - b.sort)

            // I'll try:
            let index = 0
            items.forEach(category => {
                if (index === newIndex) {
                    state.items[id].sort = index + 1
                    index += 1
                }
                if (category.id === id) {
                    return
                }
                category.sort = index + 1
                index += 1
            })
        },
        async updatePosition ({ state, getters }, { id, parentId, position }) {
            await Api.patch(getters.endpointUrl({ instance: state.items[id], path: '/position' }), { id, position, parent_id: parentId })
        }
    }
}

export default snippetCategories
