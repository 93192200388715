import axios from 'axios'
import store from '@/stores/store'
import Vue from 'vue'
import { useToast } from 'vue-toastification'

// TODO: Handle failure back in case of save!

export default class Api {
    static request ({ map, ...config }) {
        if (!map) {
            map = (d) => d
        }

        return axios.request(config).then(
            response => Promise.resolve(map(response?.data)),
            error => Promise.reject(error)
        )
    }

    static stream (url, options = {}) {
        /**
         * Stream uses a promise to let you know once the loading has been finished
         * The data returned **IS NOT** the data loaded, but the details of the stream event
         *
         * This is because when asking for N elements, only M might be returned simply
         * because the app already has the other, based on the server's cache system.
         *
         * So counting the received documents might not represents the real state.
         * Instead, the Stream system notifies that the request is done, and provide details
         * about what really should have been received. Enabling a pagination system in some cases
         */

        let fullUrl = url
        if ('params' in options) {
            const params = new URLSearchParams(options.params)
            fullUrl += '?' + params.toString().replace(/\+/g, '%20')
        }

        const fut = store.getters.getRequestPromise(fullUrl)
        if (fut) {
            return fut
        }

        const promise = new Promise((resolve, reject) => {
            options.headers = { 'Content-Protocol': 'stream', ...options.headers }
            axios.request({
                method: 'get',
                url,
                ...options
            }).then((response) => {
                if (response && response.status === 200) {
                    // 202 is the expected status for a Streamable response
                    // When the server returns 200, it means the data has been sent directly
                    console.debug('Used STREAM but got a JSON response. Endpoint not ready yet?', url, response)
                    return resolve(response.data)
                }

                const identifier = options.headers['X-Content-Identifier'] || null
                store.dispatch('onRequestStarted', {
                    id: (response?.headers) ? response.headers['x-request-id'] : null,
                    identifier,
                    url: fullUrl,
                    resolve,
                    reject
                })
            }).catch(error => {
                store.dispatch('removeRequestPromise', fullUrl)
                if (error?.status !== 401) reject(error)
            })
        })

        store.dispatch('setRequestPromise', { url: fullUrl, promise })
        return promise
    }

    static async get (url, options = {}) {
        return await this.request({ method: 'get', url, ...options })
    }

    static async post (url, data, options = {}) {
        return await this.request({ method: 'post', url, data, ...options })
    }

    static async put (url, data, options = {}) {
        return await this.request({ method: 'put', url, data, ...options })
    }

    static async patch (url, data, options = {}) {
        return await this.request({ method: 'patch', url, data, ...options })
    }

    static async delete (url, options = {}) {
        return await this.request({ method: 'delete', url, ...options })
    }

    static getCancelToken () {
        return axios.CancelToken.source()
    }

    static parseError (error, fallback = 'An error occured') {
        try {
            if (typeof error.response.data === 'object') {
                if ('errors' in error.response.data) {
                    const key = Object.keys(error.response.data.errors)[0]
                    return key + ': ' + error.response.data.errors[key]
                } else if ('error' in error.response.data) {
                    return error.response.data.error
                }
            } else if (error.response.data && error.response.data.substring(0, 1) !== '<') {
                return error.response.data
            }
        } catch (e) {}

        return fallback
    }

    static error (error, fallback = 'An error occurred') {
        /**
         * Display a Toast error with the appropriate error message
         * If the error is a 401 (authentication required), the app handles everything
         * So we remain silent, and return false
         */

        if (error?.response?.status === 401) {
            return false
        }

        useToast().error(Api.parseError(error, fallback))
    }
}

export function handleFormErrors (formRef, error, errors) {
    return new Promise((resolve, reject) => {
        let firstKeyError = null
        try {
            if (error.response && error.response.status === 429) {
                useToast().error('You have been rate-limited. Please wait a minute.')
            } else {
                if (error.response && 'errors' in error.response.data) {
                    firstKeyError = Object.keys(error.response.data.errors)[0]
                    Object.keys(error.response.data.errors).forEach(key => {
                        errors[key] = error.response.data.errors[key]
                    })
                } else {
                    useToast().error(error.response.data.error || 'An unexpected error occurred.')
                }
            }
        } catch (e) {
            useToast().error('An unexpected error occurred.')
            reject(e)
        }

        Vue.nextTick(() => {
            if (firstKeyError) {
                const field = formRef.querySelector('[name=' + firstKeyError + ']')
                if (field) {
                    field.focus()
                }

                if (formRef.querySelector('.error')) {
                    const position = formRef.querySelector('.error').getBoundingClientRect().top - document.body.getBoundingClientRect().top
                    window.scrollTo({
                        top: position,
                        behavior: 'smooth'
                    })
                }
            }

            resolve()
        })
    })
}
