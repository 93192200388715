const timers = {}

const debounce = (name, callback, wait) => {
    if ((name in timers)) {
        window.clearTimeout(timers[name])
        delete timers[name]
    }

    timers[name] = window.setTimeout(() => {
        callback()
    }, wait)
}

const unbounce = (name) => {
    window.clearTimeout(timers[name])
    delete timers[name]
}

const isMac = (/mac|ipod|iphone|ipad|macos/i.test(navigator.userAgentData?.platform || navigator.platform))

function base64urlToUint8Array (base64url) {
    const base64 = base64url.replace(/-/g, '+').replace(/_/g, '/')
    const binary = atob(base64)
    const bytes = new Uint8Array(binary.length)
    for (let i = 0; i < binary.length; i++) {
        bytes[i] = binary.charCodeAt(i)
    }
    return bytes
}

function uint8ArrayToBase64 (buffer) {
    const bytes = new Uint8Array(buffer)
    let binary = ''
    bytes.forEach(byte => { binary += String.fromCharCode(byte) })
    return btoa(binary)
}

async function copyToClipboard (value) {
    let success = false
    try {
        await navigator.clipboard.writeText(value)
        success = true
    } catch (e) {
        const input = document.createElement('textarea')
        input.textContent = value
        input.style.position = 'fixed'
        document.body.appendChild(input)
        input.select()
        try {
            success = !!document.execCommand('copy')
        } catch (e) {}
        document.body.removeChild(input)
    }

    if (success) {
        return true
    }

    throw new Error('An error occured while copying')
}

export { debounce, unbounce, isMac, base64urlToUint8Array, uint8ArrayToBase64, copyToClipboard }
